//Header
.section__headline--wrapper {
	max-width: 800px;
	text-align: center;
	margin: auto;
}
.section__header {
	padding-top: 4em;
	display: flex;
	justify-content: center;
	&-text {
		max-width: 700px;
		padding: 0 1em;
		margin: auto;
		margin-bottom: 2em;
		margin-top: 2em;
	}
}
//Holdings
.section__holdings {
	.section__holdings-content {
		min-height: 300px;
		display: flex;
		align-items: center;
		padding: 60px 0;
		.img__holdings {
			padding-left: 1em;
			width: 35%;
			display: inline-block;
			img {
				width:100%;
			}
		}
		.section__holdings-text {
			display: inline-block;
			width: 65%;
			padding: 0 2em;
			.span__holdings {
				font-size: 1.4em;
			}
			.span__holdings-subhead {
				font-size: .8em;
				font-weight: 700;
				margin-top: -.2em;
				margin-bottom: .8em;
			}
		}
	}
	.plus__spacer {
		margin-top: 30px;
	}
	.section__holdings-button {
		margin-top: 100px;
		margin-bottom: 100px;
		button {
			margin:0 auto;
			display:block;
			display: flex;
		  justify-content: center;
		}
	}
}

#holdings-content--hidden {
	display: none;
}

//Criteria
.section__criteria {
	margin-bottom: 200px;
	.section__header-text {
		margin-bottom: 100px;
			padding: 0 1em;
	}
	.section__criteria-content {
		min-height: 300px;
		padding: 2em 1.4em;
		.span__criteria {
			font-size: 1.4em;
		}
		.section__criteria-text {
			display: flex;
			align-items: flex-start;
			margin-top: 1em;
			.element__plus-section--criteria {
				width: 12px;
				padding-top: 5px;
			}
			p {
				padding-left: 1.4em;
			}
		}
	}
}

.section__contact-button {
	margin-top: 2em;
	margin-bottom: 10em;

	a {
		margin: auto;
		text-decoration: none;
		display: table;
	}
}
//Contact
#contact__form {
	display: none;
	margin-bottom:200px;
	h2 {
		margin-bottom: 100px
	}
	.section__contact-content {
		padding: 100px;
		.span__contact {
			font-size: 1.4em;
			margin-bottom: 100px;
		}
		.section__contact-content--input {
			display: flex;
			flex-wrap: wrap;
			max-width: 630px;
			justify-content: center;
			margin: auto;
			margin-top: 100px;
			margin-bottom: 60px;
			input {
				margin-bottom: 30px;
				&:nth-of-type(1) {
					margin-right: 30px;
				}
			}
			.consens__btn-primary {
				margin-top: 60px;

			}
		}
	}
}


/* media queries */
/* Large */
@media (max-width:1200px) {

}
/* Medium */
@media (max-width:992px) {
	.container {
		.border-style--left, .border-style--bottom, .border-style--top, .border-style--bottom {
			border: none;
		}
	}
	.section__holdings {
		.container {
			border-top: 1px solid $c-text;
			border-left: 1px solid $c-text;
			border-right: 1px solid $c-text;
		}
		.row__plus {
			.col-6 {
				.element__plus--right-2 {
						margin-right: -30px;
				}
				img {
					&:nth-of-type(2) {
						display: none;
					}
				}
			}
		}
	}
	.section__criteria {
		.container {
			.row {
				border-top: 1px solid $c-text;
				border-left: 1px solid $c-text;
				border-right: 1px solid $c-text;
			}
			.row__plus {
				.col-12 {
					.element__plus--right {
							margin-right: -29px;
					}
					img {
						&:nth-of-type(2) {
							display: none;
						}
					}
				}
			}
		}
	}
	#contact__form {
		.section__contact-content {
			.section__contact-content--input {
				input {
					width:100%;
					&:nth-of-type(1) {
						margin-right: 0;
					}
				}
			}
		}
	}
	.col-mobile {
		display: none;
	}
}
/* Small */
@media (max-width:768px) {
	.section__holdings {
		.container {
			border-top: 1px solid $c-text;
			border-left: 1px solid $c-text;
			border-right: 1px solid $c-text;
		}
		.row__plus {
			.col-6 {
				img {
					&:nth-of-type(2) {
						display: none;
					}
				}
			}
		}
	}
	.container {
		.border-style--left, .border-style--bottom, .border-style--top, .border-style--bottom {
			border: none;
		}
		.col-mobile {
			display: none;
		}
	}
}
/* Extra Small */
@media (max-width:576px) {
	section {
		.container {
			border-left: none;
			border-right: none;
		}
		.element__line-outer--left, .element__line-outer--right {
			display: none;
		}
		.row__plus {
			display: none;
		}
		.border-style--left, .border-style--bottom, .border-style--top, .border-style--bottom {
			border: none;
		}
	}
	.section__holdings {
		.container {
			border-left: none;
			border-right: none;
		}
		.section__holdings-content {
			flex-flow: column;
			text-align: center;
			.section__holdings-text {
				margin-top: 1em;
				width: 100%;
				text-align: center;
				.consens__btn-primary {
					margin-top: 1em;
					justify-content: center;
				}
			}
			.img__holdings {
				width: 50%;
					padding-left: 0;
			}
		}
	}
	.section__criteria {
		margin-bottom: 60px;
		.container {
			.section__criteria-content {
				border-top: 1px solid $c-text;
			}
			.row {
				border:none;
			}
		}
	}
}
