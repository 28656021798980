//buttons
.consens__btn-primary {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: .7em;
  margin-right: 1.4em;
  a {
    text-decoration: none;
  }
  img {
    width: 20px;
    margin-right: 4px;
  }
  .consens__btn-primary--style {
    background-color: #fff;
    font-size: 1em;
    font-weight: 400;
    border: none;
    border-bottom: 2px solid $c-text;
    &:hover {
      cursor: pointer;
      border-color: $c-btn-secondary;
    }
  }
}

.consens__btn-secondary {
  border: none;
  display: inline;
  color: $c-btn-secondary;
  background-color: $c-text;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1em;
  padding: .8em 1.2em;
  &:hover {
    color: $c-text;
    background-color: $c-btn-secondary;
    cursor: pointer;
  }
}
