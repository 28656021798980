//Header from consens music
.header__main {
	background-color: $c-text;
	min-height: 300px;
	overflow: hidden;
	position: relative;
	.header__content {
		padding: 80px 0;
		.section__back {
			margin-bottom: 15px;
			a {
				span {
					color: #fff;
					background-color: $c-text;
				}
			}
		}
		.header__content--text {
			h1 {
				display: block;
			}
			.text__subhead {
				font-size: 1.4em;
				color: #fff;
			}
			p {
				margin-top: 30px;
			}
		}
	}
}

.section__content {
	padding: 100px 0;
}

.content__headline {
	display: block;
	margin-bottom: 40px;
}

// imprint styles from consens music
.imprint__address {
  margin-bottom: 60px;
  .imprint__address--wrapper {
    margin-bottom: 60px;
    .imprint__address--headline {
      font-size: 1.6em;
      margin-bottom: 20px;
    }

    .imprint__address--paragraph {
      p {
        margin-top: 10px;
      }
    }
  }
}

.imprint__content {
  .imprint__content--paragraph {
    margin-bottom: 40px;
    span {
      font-size: 1.6em;
    }
    p {
      margin-top: 10px;
    }
    ul {
      margin-top: 10px;
      padding-left: 20px;
      li {
        padding-bottom: 12px;
        &:last-of-type {
          padding-bottom: 20px;
        }
      }
    }
  }
  .content__headline {
    margin-bottom: 40px;
  }
}

@media (max-width:992px) {
  .imprint__address {
			margin-bottom: 0;
		}
}

@media (max-width:576px) {
	.header__content {
		h1 {
			font-size: 2.4em;
		}
	}
	.imprint__content {
		h2 {
			font-size: 2.2em;
		}
	}
}
