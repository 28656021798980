//Footer
.footer {
	padding-top: 1.500em;
	padding-bottom: 4em;
	font-weight: 400;
	font-size: 1em;

	.footer__bullet {
		height: 6px;
		width: 6px;
		margin-right: .5em;
		margin-left: .5em;
		margin-bottom: .250em;
		background-color: $c-text;
		border-radius: 50%;
		display: inline-block;
	}
	.footer__link {
		font-weight: 400;
		text-decoration: none;
		border-bottom: 2px solid black;

		&:hover {
			border-color: $c-btn-secondary;
		}
	}
}

@media (max-width: 1200px) {
  .footer {
		text-align: center;

		.row div {
			padding-bottom: .750em;
		}
	}
}

@media (max-width: 768px) {
	.footer {
		padding-bottom: 2em;

		.footer__bullet {
			display: block;
			background-color:white;
			margin: 0;
			width: 0;
			height: 0;
		}
	}
}
