
* {
  margin: 0;
  padding: 0;
}

html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
    overflow-x: hidden !important;
}
body {
	min-width:300px;

}

html, body {
  scroll-behavior: smooth;
}

//Helper
input:focus, textarea:focus, select:focus, button:focus  {
  outline: none;
}

//Elements

.element__logo {
  width: 300px;
  position: relative;
  left: 50%;
  transform: translateX(-39.5%);
  display: block;
  margin-bottom: 120px;
}


.element__plus {
  width: 59px;
  display: inline-block;
  position: absolute;
  &--center {
    width: 59px;
    display: block;
    position: relative;
    margin-left: calc(50%);
    transform: translateX(-50%);
  }
  &--left {
    margin-top: -29px;
    margin-left: -44px;
    &---mobile {
      display: none;
    }
  }
  &--right {
    right: 0;
    margin-top: -29px;
    margin-right: -30px;
  }
  &--right-2 {
    right: 0;
    margin-top: -29px;
    margin-right: -29px;
  }

}

.row__plus {
  height: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.element__minus {
  max-width: 64px;
  display: block;
  &-center {
    margin: auto;
    margin-top: 1.5em;
  }
}


.element__line {
  content: "";
  background-color: #000;
  position: relative;
  display: block;
  &-outer--left {
    position: absolute;
    left: 0;
    z-index: -10;
    height: 1px;
    width: 33.3%;
  }
  &-outer--right {
    position: absolute;
    right: 0;
    z-index: -10;
    height: 1px;
    width: 33.3%;
  }
  &-vertical {
    left: 50%;
    width: 1px;
    margin-bottom: 2em;
    &--small {
      height: 100px;
    }
    &--medium {
      height:160px;
    }
    &--large {
      height: 240px;
      margin-bottom: 0;
    }
  }

  &-center--small {
    height: 80px;
  }
  &-center--large {
    height: 240px;
  }
}

.element__curve {
  position: absolute;
  z-index: -10;
  left: 50%;
  transform: translateY(-99.2%);
  width: 50%;
  overflow: hidden !important;
}

//Borders
.border-style--left {
  border-left: 1px solid $c-text;
}
.border-style--right {
  border-right: 1px solid $c-text;
}
.border-style--top {
  border-top: 1px solid $c-text;
}
.border-style--bottom {
  border-bottom: 1px solid $c-text;
}
.border-style--contact {
  border: 1px solid $c-text;
  border-bottom: none;
}

.border-style--text {
  border-bottom: 1px solid;
  border-spacing: 10px;
   &---blue {
     border-color: $c-text-cyan ;
   }
   &---red {
     border-color: $c-text-red;
   }
}

//forms

.input__field-small {
  box-sizing: border-box;
  border: none;
  width: 300px;
  height: 30px;
  border-bottom: 1px solid $c-text;
}

.input__field-big {
  box-sizing: border-box;
  border: none;
  width: 630px;
  height: 30px;
  border-bottom: 1px solid $c-text;
}

.input__field-message {
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid $c-text;
}

/* media queries */
/* Large */
@media (max-width:1200px) {

}
/* Medium */
@media (max-width:992px) {
  .element__curve {
    display: none;
  }
  .element__plus--left---mobile {
    display: inline-block;
  }
}
/* Small */
@media (max-width:768px) {
	.element__logo {
	  width: 200px;
	  position: relative;
	  left: 50%;
	  transform: translateX(-39.5%);
	  display: block;
	  margin-bottom: 120px;
	}
}
/* Extra Small */
@media (max-width:576px) {

}
