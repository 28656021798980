body {
font-family: 'Heebo', sans-serif;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	line-height: 1.5;
	font-size: 1em;
	color: #000;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

.text-bold {
	font-weight: 700;
}

.text-small {
	font-size: .8em;
}

.missing {
	color: red;
}
//Headlines

h1 {
  font-family: "Heebo";
	font-weight: 700;
	font-size: 4.5em;
	line-height: 1.2;
	display: inline;
}

h2 {
  font-family: "Heebo";
	font-weight: 700;
	font-size: 2.6em;
	line-height: 1.4;
	display: inline;
}

//Other

span {
	font-family: 'Heebo', sans-serif;
	font-weight: 700;
	font-size: 1em;
}

p {
	font-family: 'Heebo', sans-serif;
	font-weight: 400;
	font-size: 1em;
}

a {
	font-family: 'Heebo', sans-serif;
	font-weight: 400;
	font-size: 1em;
	text-decoration: underline;
	color: $c-text;
}

input, textarea {
	color: #000;
	font-family: "Heebo";
	font-weight: 400;
	font-size: 1em;
}

textarea {
	resize: vertical;
}

@media (max-width:768px) {
  	h1 {
			font-size: 4em;
		}
}
